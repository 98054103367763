import styled from 'styled-components';

import { Button as AntButton } from 'antd';

export const HoverButton = styled(AntButton)`
  /* Global Adjustments */
  text-shadow: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease,
    background 0.1s ease;
  & > *:not(:only-child):first-child {
    margin-right: 0.25em;
  }
  svg {
    vertical-align: text-bottom;
  }

  /* Hover Button */
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-color: transparent;
  font-size: 15px;
  line-height: 20px;
  padding: 5px 10px;
  color: ${props => props.theme.color.grey87};
  &:hover,
  &:active,
  &:focus {
    background-color: rgba(256, 256, 256, 0.5);
    color: initial;
  }
`;

export const OutlinedHoverButton = styled(HoverButton)`
  border: 3px solid ${props => props.theme.color.grey87};
  align-items: center;
  padding: 0 10px;
  &:hover,
  &:active,
  &:focus {
    border: 3px solid ${props => props.theme.color.grey87};
  }
`;
