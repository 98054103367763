import Button from './Button';
import styled from 'styled-components';

export const LeftButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  @media only screen and (max-width: 575px) {
    padding-left: 6px;
    padding-right: 6px;
  }
`;
export const LeftButtonBordered = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 3px solid white !important;
  @media only screen and (max-width: 575px) {
    padding-left: 6px;
    padding-right: 6px;
  }
`;

export const MiddleButton = styled(Button)`
  border-radius: 0;
  border-left: 3px solid white;
  border-right: 3px solid white;
  &:hover,
  &:active,
  &:focus {
    border-color: white;
  }
  @media only screen and (max-width: 575px) {
    padding-left: 6px;
    padding-right: 6px;
  }
`;

export const RightButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @media only screen and (max-width: 575px) {
    padding-left: 6px;
    padding-right: 6px;
  }
`;
