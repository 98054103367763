import styled from 'styled-components';
import { darken, saturate } from 'polished';

import { Button as StyledButton } from 'antd';

const Button = styled(StyledButton)`
  /* Global Adjustments */
  text-shadow: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease,
    background 0.1s ease;
  & > *:not(:only-child):first-child {
    margin-right: 0.25em;
  }
  svg {
    vertical-align: ${props => (props.icononly === 'true' ? 'middle' : 'text-bottom')};
    font-size: 1.25em;
  }
  width: ${props => props.icononly === 'true' && '36px'};

  /* Pass props primary, secondary or tertiary to color prop */
  ${props => {
    if (props.color === 'primary') {
      props.color = props.theme.color.primary;
    } else if (props.color === 'secondary') {
      props.color = props.theme.color.secondary;
    } else if (props.color === 'tertiary') {
      props.color = props.theme.color.tertiary;
    } else if (props.color === 'facebook') {
      props.color = props.theme.color.facebook;
    } else if (props.color === 'google') {
      props.color = props.theme.color.google;
    }
  }}

  ${props =>
    props.color && {
      color: props.fg ? props.fg : 'white',
      backgroundColor: props.color,
    }}

  /* Hover, Active, and Focus */
  &:hover,
  &:active,
  &:focus {
    color: ${props => (props.fg ? props.fg : props.color ? 'white' : 'rgba(0,0,0,.8)')};
    background-color: ${props =>
      (props.color && saturate(0.1, darken(0.05, props.color))) || '#cacbcd'};
    svg {
      opacity: 0.85;
    }
  }

  &[disabled] {
    opacity: 0.6;
    cursor: default;
  }

  ${props => props.boxshadow && { boxShadow: props.theme.boxShadow.button }}
`;

export default Button;
