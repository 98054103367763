import styled from 'styled-components';
import { HoverButton } from './index';
// import PropTypes from 'prop-types';

const VoteButton = styled(HoverButton)`
  padding: 0 5px;
  font-size: 12px;
  height: fit-content;
  border: 3px solid ${props => props.theme.color.primary};
  color: ${props => (props.voted === 'true' ? 'white' : props.theme.color.primary)};
  background-color: ${props => (props.voted === 'true' ? props.theme.color.primary : 'white')};
  &:hover,
  &:active {
    background-color: ${props => props.theme.color.primary};
    border-color: 3px solid ${props => props.theme.color.primary};
    color: white;
  }
  :focus {
    background-color: ${props => (props.voted === 'true' ? props.theme.color.primary : 'white')};
    border-color: 3px solid ${props => props.theme.color.primary};
    color: ${props => (props.voted === 'true' ? 'white' : props.theme.color.primary)};
  }
`;

export default VoteButton;
