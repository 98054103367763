import styled from 'styled-components';
import { Button } from './index';

const InvertedButton = styled(Button)`
  border-radius: 10px;
  padding: 0.5em;
  &:hover,
  &:active,
  &:focus {
    svg {
      opacity: 1;
    }
  }
`;

export default InvertedButton;
