import {
  Affix as AntAffix,
  Alert as AntAlert,
  Avatar as AntAvatar,
  BackTop as AntBackTop,
  Badge as AntBadge,
  Breadcrumb as AntBreadcrumb,
  Carousel as AntCarousel,
  Col as AntCol,
  Collapse as AntCollapse,
  Divider as AntDivider,
  Dropdown as AntDropdown,
  Empty as AntEmpty,
  Layout as AntLayout,
  List as AntList,
  Menu as AntMenu,
  message as AntMessage,
  Modal as AntModal,
  Pagination as AntPagination,
  Popconfirm as AntPopConfirm,
  Popover as AntPopover,
  Progress as AntProgress,
  Radio as AntRadio,
  Row as AntRow,
  Skeleton as AntSkeleton,
  Slider as AntSlider,
  // Select as AntSelect,
  // Slider as AntSlider,
  Spin as AntSpin,
  Switch as AntSwitch,
  Table as AntTable,
  Tabs as AntTabs,
  Tag as AntTag,
  Tooltip as AntTooltip,
  Typography as AntTypography,
} from 'antd';
import styled from 'styled-components';
// Form Elements With Their Own Folders
import { Button as AntButton } from './Buttons';
import { Ant, Formik as FormikElements } from './form';

// Default Form Elements
export const AutoComplete = Ant.AutoComplete;
export const Checkbox = Ant.Checkbox;
export const Form = Ant.Form;
export const Input = Ant.Input;
export const Rate = styled(Ant.Rate)`
  .ant-rate-star:not(:last-child){
      margin-right:3px;
  }
  .ant-rate-star-full{
      transition: all 0.1s ease;

      /* eslint-disable-next-line prettier/prettier */
      filter: drop-shadow(0px -1px 0px ${props =>
        props.theme.color.starOutline}) drop-shadow(-1px 0px 0px ${props =>
  props.theme.color.starOutline}) drop-shadow(0px 1px 0px ${props =>
  props.theme.color.starOutline}) drop-shadow(1px 0px 0px ${props =>
  props.theme.color.starOutline});

      .ant-rate:hover & {
          color: ${props => props.theme.color.starselected};
          filter: drop-shadow(0px -1px 0px ${props =>
            props.theme.color.starSelectedOutline}) drop-shadow(-1px 0px 0px ${props =>
  props.theme.color.starSelectedOutline}) drop-shadow(0px 1px 0px ${props =>
  props.theme.color.starSelectedOutline}) drop-shadow(1px 0px 0px ${props =>
  props.theme.color.starSelectedOutline});
      }

      /* TODO - Figure out how to do this CSS command in styled components */
      /* &:hover, .ant-rate-star:hover ~ .ant-rate-star-full{ color: ${props =>
        props.theme.color.starselected}; filter: drop-shadow(0px -1px 0px ${props =>
  props.theme.color.starSelectedOutline}) drop-shadow(-1px 0px 0px ${props =>
  props.theme.color.starSelectedOutline}) drop-shadow(0px 1px 0px ${props =>
  props.theme.color.starSelectedOutline}) drop-shadow(1px 0px 0px ${props =>
  props.theme.color.starSelectedOutline}); } */
      
  }
  .ant-rate-star-empty{
      filter: drop-shadow(0px 0px 1px white);
  }
  .ant-rate-star-first{
      transition: none;
  }
`;
export const Select = Ant.Select;

// Formik Elements
export const Formik = FormikElements;

// Non-Formik Elements
export const Affix = styled(AntAffix)``;
export const Alert = styled(AntAlert)``;
export const Avatar = styled(AntAvatar)``;
export const BackTop = styled(AntBackTop)``;
export const Breadcrumb = styled(AntBreadcrumb)`
  text-align: center;
  a {
    transition-duration: 0.1s;
    color: black;
    &:hover {
      color: ${props => props.theme.color.tertiary};
    }
  }
`;
export const Badge = styled(AntBadge)``;
export const Button = styled(AntButton)``;
export const Carousel = styled(AntCarousel)``;
export const Col = styled(AntCol)``;
export const Collapse = styled(AntCollapse)`
  border: none;
  .ant-collapse-content,
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-header {
    line-height: 1em;
  }
  .ant-collapse-item > .ant-collapse-header > svg.ant-collapse-arrow {
    font-size: 1.5em;
    left: 10px;
    margin-right: 0.25em;
    transition: transform 0.24s;
    color: ${props => props.theme.color.primary};
  }
  .ant-collapse-item-active > .ant-collapse-header > svg.ant-collapse-arrow {
    transform: rotate(90deg);
    transition: transform 0.24s;
  }
`;
export const Divider = styled(AntDivider)``;
export const Dropdown = styled(AntDropdown)`
  overflow: hidden;
  .ant-dropdown-menu-item-divider {
    margin: 7px 0;
  }
  ul {
    overflow: hidden;
  }
`;
export const Empty = styled(AntEmpty)``;
export const Layout = styled(AntLayout)``;
export const List = styled(AntList)`
  * > .ant-list-split .ant-list-item {
    border: none;
  }
`;
export const Menu = styled(AntMenu)``;
export const message = AntMessage;
export const Modal = styled(AntModal)`
  .ant-modal-header {
    text-align: center;
    .ant-modal-title {
      font-size: 20px;
    }
  }
`;
export const Pagination = styled(AntPagination)``;
export const Popover = styled(AntPopover)``;
export const Popconfirm = styled(AntPopConfirm)``;
export const Progress = styled(AntProgress)``;
export const Radio = styled(AntRadio)`
  .ant-radio-button-wrapper {
    border: none !important;
  }
`;
export const Row = styled(AntRow)``;
export const Slider = styled(AntSlider)``;
export const Skeleton = styled(AntSkeleton)`
  .ant-skeleton-title,
  .ant-skeleton-paragraph > li {
    border-radius: 10px;
  }
`;
export const Spin = styled(AntSpin)``;
export const Switch = styled(AntSwitch)``;
export const Table = styled(AntTable)``;
export const Tabs = styled(AntTabs)`
  transition: all 0.3s ease;

  .ant-tabs-bar,
  .ant-tabs-nav {
    margin: 0px;
  }

  .ant-tabs-nav .ant-tabs-tab:active {
    color: inherit;
  }

  /* .ant-tabs-nav .ant-tabs-tab:hover {
    color: inherit;
  } */
`;
export const Tag = styled(AntTag)`
  border-radius: ${props => props.theme.borderRadius.sm};
  margin: 0 0.25em 0.25em 0;
`;
export const Text = styled(AntTypography.Text)`
  /* Color Theme */
  &&& {
    ${props => props.color && { color: props.theme.color[props.color] }}
  }
`;
export const Title = styled(AntTypography.Title)`
  text-align: center;
  /* Color Theme */
  &&& {
    ${props => props.color && { color: props.theme.color[props.color] }}
  }
`;
export const Tooltip = styled(AntTooltip)``;
