import styled, { css } from 'styled-components';

import { AutoComplete, Checkbox, Form, Input, Radio, Rate, Select } from 'antd';
import {
  AutoComplete as FormikAutoComplete,
  Checkbox as FormikCheckbox,
  Form as FormikForm,
  Input as FormikInput,
  Radio as FormikRadio,
  Rate as FormikRate,
  Select as FormikSelect,
} from 'formik-antd';

export const autoCompleteStyle = css``;

export const checkboxStyle = css``;

export const radioStyle = css`
  .ant-radio-button-wrapper {
    border: none;
  }
`;

export const rateStyle = `
  vertical-align: text-bottom;
  line-height: 20px;
  min-width: 115px;
  .ant-rate{
      vertical-align: text-bottom;
      line-height: 20px;
  }
  .ant-rate-star:not(:last-child){
      margin-right:3px;
  }
  .ant-rate-star-full{
      transition: all 0.1s ease;
      /* // eslint-disable-next-line prettier/prettier */
      filter: drop-shadow(0px -1px 0px ${props =>
        props.theme.color.starOutline}) drop-shadow(-1px 0px 0px ${props =>
  props.theme.color.starOutline}) drop-shadow(0px 1px 0px ${props =>
  props.theme.color.starOutline}) drop-shadow(1px 0px 0px ${props =>
  props.theme.color.starOutline});

      .ant-rate:hover & {
          color: ${props => props.theme.color.starSelected};

          /* // eslint-disable-next-line prettier/prettier */
          filter: drop-shadow(0px -1px 0px ${props =>
            props.theme.color.starSelectedoutline}) drop-shadow(-1px 0px 0px ${props =>
  props.theme.color.starSelectedoutline}) drop-shadow(0px 1px 0px ${props =>
  props.theme.color.starSelectedoutline}) drop-shadow(1px 0px 0px ${props =>
  props.theme.color.starSelectedoutline});
      }

      /* TODO - Figure out how to do this CSS command in styled components */
      /* // eslint-disable-next-line prettier/prettier */
      /* &:hover, .ant-rate-star:hover ~ .ant-rate-star-full{ color: ${props =>
        props.theme.color.starSelected}; filter: drop-shadow(0px -1px 0px ${props =>
  props.theme.color.starSelectedoutline}) drop-shadow(-1px 0px 0px ${props =>
  props.theme.color.starSelectedoutline}) drop-shadow(0px 1px 0px ${props =>
  props.theme.color.starSelectedoutline}) drop-shadow(1px 0px 0px ${props =>
  props.theme.color.starSelectedoutline}); } */

  }
  .ant-rate-star-empty{
      filter: drop-shadow(0px 0px 1px white);
  }
  .ant-rate-star-first{
      transition: none;
  }
`;

export const Ant = {
  AutoComplete: styled(AutoComplete)`
    ${autoCompleteStyle}
  `,
  Checkbox: styled(Checkbox)`
    ${checkboxStyle}
  `,
  Form: styled(Form)``,
  Input: styled(Input)`
    &:focus,
    .ant-input-focused {
      border-color: transparent;
      box-shadow: none;
    }
  `,
  Radio: styled(Radio)`
    ${radioStyle}
  `,
  Rate: styled(Rate)`
    ${rateStyle}
  `,
  Select: styled(Select)``,
};

export const Formik = {
  AutoComplete: styled(FormikAutoComplete)`
    ${autoCompleteStyle}
  `,
  Checkbox: styled(FormikCheckbox)`
    ${checkboxStyle}
  `,
  Form: styled(FormikForm)``,
  Input: styled(FormikInput)``,
  Radio: styled(FormikRadio)`
    ${radioStyle}
  `,
  Rate: styled(FormikRate)`
    ${rateStyle}
  `,
  Select: styled(FormikSelect)``,
};
