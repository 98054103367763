import _get from 'lodash/get';
import styled from 'styled-components';
import { Button } from './index';

const RadioButton = styled(Button)`
  border-radius: ${props => props.theme.borderRadius.md};
  background-color: ${props =>
    _get(props, 'checked', false) ? props.theme.color.primaryLight : 'transparent'};
  box-shadow: none;
  color: ${props => props.theme.color.primary};
  line-height: 32px;
  height: 32px;
  padding: 0 0.5em;
  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.color.primaryLight};
    color: ${props => props.theme.color.primary};
  }
`;

export default RadioButton;
